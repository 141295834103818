<template>
  <div>
    <full-page-content-container>
      <grid-table
        :column-defs="columnDefs"
        :row-data="rowData"
        v-if="rowData.length > 0"
      >
        <template v-slot:cell-renderer>
          <div>
            <span class="edit-icon" @click="confirmPromoCodeDelete"
              ><img
                :src="require('@/assets/images/icons/edit.svg')"
                alt="edit icon"
            /></span>
          </div>
        </template>
      </grid-table>
      <empty-dataset v-else></empty-dataset>
    </full-page-content-container>
  </div>
</template>

<script>
import gridTableParentMixin from "../../../../../mixins/gridTableParentMixin";
import axios from "../../../../../axios";
import FullPageContentContainer from "../../../../layouts/components/fullPage/FullPageContentContainer";
import GridTable from "../../../../components/tables/GridTable";
import EmptyDataset from "../../../../components/displays/EmptyDataset";

export default {
  components: { EmptyDataset, GridTable, FullPageContentContainer },
  mixins: [gridTableParentMixin],
  name: "PromoCodes",
  data() {
    return {
      columnDefs: [
        {
          headerName: "Promo Code",
          field: "code",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Use Count",
          field: "times_redeemed",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Max Use Count",
          field: "max_redemptions",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Expires At",
          field: "expires_at",
          type: "date",
          dateFormat: "timestamp",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Active",
          field: "active",
          filter: true,
          sortable: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/coupons/${this.$route.params.couponId}/promo-codes`,
    };
  },

  methods: {
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          console.log(res);
          this.rowData = res.data.data.data;
          // if (this.rowData <= 0) {
          //   this.$toast("No promo codes were found for this coupon", {
          //     type: "info",
          //     position: "top-center",
          //   });
          // }
          loading.close();
          // this.closePageLoader();
        })
        .catch((err) => {
          loading.close();
          this.$toast(err.response.data.message, {
            type: "error",
            position: "top-center",
          });
          console.log(err.response);
        });
    },

    confirmPromoCodeDelete() {},
    cancelPromoCodeDelete() {},
  },

  created() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
  },
};
</script>

<style scoped></style>
